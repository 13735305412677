//js
if (!global._babelPolyfill) {
  require("babel-polyfill");
}
require("./js/slider");
require("./js/scroll");
require("./js/helper");
require("./js/navigation");
require("./js/maps");
require("./js/main");
require("./js/numScroller");

//fonts
require("./fonts/manrope/font.css");

// css
import "normalize.css";
require("./sass/style.scss");
