//https://www.npmjs.com/package/smooth-scroll

import SmoothScroll from "smooth-scroll";
import ScrollMagic from "scrollmagic";

new SmoothScroll('a[href*="#"]', {
  ignore: "[data-vc-container]",
  header: "#masthead"
});

const controller = new ScrollMagic.Controller();

const sections = document.querySelectorAll("article > section");
sections.forEach(el => {
  new ScrollMagic.Scene({
    triggerElement: el
  })
    .offset(window.getComputedStyle(el, null).getPropertyValue("margin-top"))
    .duration(el.clientHeight)
    .addTo(controller)
    .on("enter", event => {
      const currentSectionID = event.target.triggerElement().getAttribute("id");
      const menuElement = document.querySelector(
        `a[href*="${currentSectionID}"]`
      );
      if (menuElement) {
        menuElement.parentNode.classList.add("active");
      }
    })
    .on("leave", event => {
      const currentSectionID = event.target.triggerElement().getAttribute("id");
      const menuElement = document.querySelector(
        `a[href*="${currentSectionID}"]`
      );
      if (menuElement) {
        menuElement.parentNode.classList.remove("active");
      }
    });
});
