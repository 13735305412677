import { isMobile } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import FloatLabels from "./forms/float-labels";
import ToggleContent from "./toggleContent";

window.addEventListener("load", () => {
  new SiteHeader({
    setBodyPaddingTop: false
  });
  new FloatLabels();
  new ToggleContent(".hover-images__read-more-btn");

  toggleMenu();

  if (isMobile()) {
    closeMenu();
    addSticky();
  }

  document.addEventListener(
    "wpcf7submit",
    function(event) {
      if (37 == event.detail.contactFormId) {
        window.location = "https://mikolajbasinski.pl/formularz-dziekuje";
      }
    },
    false
  );
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};
