import CoreAnimations from "./animations/CoreAnimation";

class ToggleContent {
  constructor(btn) {
    this.button = btn;
    this.init();
  }
  init() {
    document.querySelectorAll(this.button).forEach(btn => {
      btn.addEventListener("click", () => {
        CoreAnimations.slideToggle(btn.nextElementSibling);
        btn.classList.toggle("active");
      });
    });
  }
}

export default ToggleContent;
